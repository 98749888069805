import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const ContainerNavBar = ({logoUrl, questionData, handlePageJump, pageIndex, displayNav}) => {
    return (
        <Navbar bg="white" expand="lg" className="p-2 pb-0">
        { logoUrl !== '' && 
            <Navbar.Brand>
                <img src={logoUrl} className="d-inline-block align-bottom" style={{ maxHeight: 80, maxWidth: '45vw' }}/>
            </Navbar.Brand>
        }
        { displayNav && 
            <>
                <Navbar.Toggle className="mr-2" />
                { questionData && questionData.pages && questionData.pages.length > 1 &&
                    <Navbar.Collapse className="mt-auto">
                        <Nav className="justify-content-end w-100 mb-0">
                        {questionData.pages.map((x, index) => {
                            return (
                                        <Nav.Link
                                            key={index}
                                            onClick={() => handlePageJump(index)}
                                            className={`p-2 ml-2 ${(index === pageIndex ? "active" : "")} nav-tabs mshNavTab`}>
                                            <span>{x.title}</span></Nav.Link>
                                    )
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                }
            </>
        }            
        </Navbar>
    )
}


export default ContainerNavBar;