import React from 'react';
import QuestionnaireContainer from '../QuestionnaireContainer';
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

    let route = window.location.pathname.split('/');

    // /tenant/site/questionnaireId
    let routeDetails = {
        slug: route[1],
        siteId: "_",
        questionnaireId: "defaultQuestionnaire"
    };

    if (route.length > 2) {
         routeDetails.siteId = route[2];
    }

    if (route.length > 3) {
         routeDetails.questionnaireId = route[3];
    }

    return (
    <div className="container">
    <div className="row">
        <div className="col-12 m-xs-0 m-sx-2">
            <QuestionnaireContainer routeInfo={routeDetails}/>
        </div>
    </div>
    </div>
    )
}

export default App;