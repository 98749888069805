import React, { useState, useEffect, useRef } from 'react';
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import showdown from 'showdown';
import ContainerNavBar from './ContainerNavBar';
import TenantStyle from './TenantStyle';

const QuestionnaireContainer = ({routeInfo}) => {
    let {slug, siteId, questionnaireId} = routeInfo;

    const LOCALSTORAGEKEY = "MSH_CURRENT_APP";
    const [title, setTitle] = useState('');
    const [appId, setAppId] = useState(slug);
    const [loadError, setLoadError] = useState('');
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [questionData, setQuestionData] = useState({});
    const [pageIndex, setPageIndex] = useState(0);
    const [lastPageIndex, setLastPageIndex] = useState(0);
    const [completeClicked, setCompleteClicked] = useState(false);
    const [displayNav, setDisplayNav] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [customCSS, setCustomCSS] = useState('');
    const [customColors, setCustomColors] = useState({});

    const target = useRef(null);

    useEffect(() => {
        if (!slug || slug.trim() === "") {
            setLoading(false);
            setLoadError("No Application Specified.")
            return;
        }

        let mdConverter = new showdown.Converter();

        let fetchUrl = `${process.env.REACT_APP_API_ROOT}/${process.env.REACT_APP_API_APP_GET}?` +
                        `slug=${slug}&id=${questionnaireId}`;

        fetch(fetchUrl)
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    throw new Error("This domain is not authorized to diplay the requested employment application.");
                }
                return response.json();
            })
            .then(data => {
                if (data.definition === undefined) {
                    throw new Error('Error retrieving application definition');
                }
                setLoading(false);
                setIsLoaded(true);
                setSubmitError('');
                let questionData = JSON.parse(
                    data.definition
                        .replace(/%%MSHAPIURL%%/g, process.env.REACT_APP_API_ROOT)
                        .replace(/%%HASH%%/g, data.hash)
                        .replace(/%%SLUG%%/g, slug)
                        .replace(/%%APIUSSTATES%%/g,'https://country-state-worker.mysimplehire.workers.dev/api/usstates')
                        .replace(/%%APICOUNTRIES%%/g,'https://country-state-worker.mysimplehire.workers.dev/api/countries')
                    );
                setQuestionData(questionData);
                setAppId(data.questionnaireId);

                document.title = (questionData.title ?? "") + " Application";

                setTitle(document.title);
                
                if (data.background && data.background !== '') { 
                    document.body.background = data.background;
                }

                if (data.logo && data.logo !== '') {
                    setLogoUrl(data.logo);
                }

                if (data.customCSS && data.customCSS !== '') {
                    setCustomCSS(data.customCSS);
                }

                setCustomColors(data.customColors);

                let localAnswers = JSON.parse(localStorage.getItem(`${LOCALSTORAGEKEY}.${appId}`) ?? `{"startTimeStamp": ${new Date().getTime()}}`);

                let surveyRef = target.current.survey;
                
                let answers = { ... surveyRef?.data, ...localAnswers };
        
                surveyRef.data = answers;
                surveyRef.css = {
                    matrixdynamic: {
                        iconAdd: "fa fa-plus",
                        iconRemove: "fa fa-trash"
                    }
                };
                surveyRef.onTextMarkdown
                            .add((_, options) => {
                                var str = mdConverter.makeHtml(options.text);
        
                                //remove root paragraphs <p></p>
                                str = str.substring(3);
                                str = str.substring(0, str.length - 4);
                                
                                options.html = str;
                            });
                surveyRef.checkErrorsMode = 'onComplete';
                
                setLastPageIndex(surveyRef.visiblePages.length - 1);
                setDisplayNav(true);

            })
            .catch((error) => {
                setLoading(false);

                let errorMessage = error && error.message && error.message === "Failed to fetch"
                                    ? "There was a network error :("
                                    : error.toString();

                setLoadError(errorMessage);
                
            });

    }, [slug, appId, questionnaireId]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_V3}`;
        script.async = true;
        document.body.appendChild(script);
      return () => {
          document.body.removeChild(script);
        }
      }, []);

    const handleCompleteClicked = () => {
        setCompleteClicked(true);
        var isComplete = false;

        let surveyRef = target.current.survey;
        if (surveyRef.currentPageNo === surveyRef.visiblePages.length - 1)
        {
            isComplete = surveyRef.completeLastPage();
        } else {    
            if (!surveyRef.currentPage.hasErrors(true, true)) {

                isComplete = true;
                var pages = surveyRef.visiblePages;    
    
                for(var i = 0; i < pages.length; i ++) {
                    if (surveyRef.currentPageNo === i)
                        continue;
        
                    if (pages[i].hasErrors(true, true))
                    {
                        isComplete = false;
                        handlePageJump(i);
                        break;
                    }
                }    
    
            }    
        }

        if (isComplete) {
            handleOnComplete();
        } else {
            setCompleteClicked(false);
            incompleteScroll();
        }
    }

    const handleRecheck = () => {
        window.location.href = window.location.href;
    }

    function incompleteScroll() {
        setTimeout(() => forceScrollToNearestQuestion(), 500);
    }
    function forceScrollToNearestQuestion() {
        let surveyRef = target.current.survey;
        var questions = surveyRef.getAllQuestions(true); //get all visible questions
        for(var i = 0; i < questions.length; i ++) {
                if (questions[i].errors.length > 0)
                {
                    questions[i].focus(true);
                    break;
                }
        }
    }

    const handleOnComplete = () => {
        setIsSubmitting(true);
        setDisplayNav(false);
        setSubmitError('');
        let surveyRef = target.current.survey;

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_V3, {action: 'submit'}).then((token) => {
                if (siteId === "_") {
                    if (surveyRef.data.siteId && surveyRef.data.siteId !== "") {
                        siteId = surveyRef.data.siteId;
                    }
                    
                    if (siteId === "_" && surveyRef.data.selectedCenterLocationInternational && surveyRef.data.selectedCenterLocationInternational !== "") {
                        siteId = surveyRef.data.selectedCenterLocationInternational;
                    }
                }
                fetch(`${process.env.REACT_APP_API_ROOT}/${process.env.REACT_APP_API_APP_POST}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token,
                        slug,
                        appId,
                        siteId,
                        answers: surveyRef.data
                    })
                })
                .then(submitResult => submitResult.json())
                .then((result) => {
                    if (result && result.status && result.status === 200) {
                        setSubmitted(true);
                        setSubmitError('');
                        localStorage.clear();
                    } else {
                        setSubmitted(false);
                        setSubmitError((result && result.error) || "Unexpected error");
                        console.log(result);
                    }
                    setIsSubmitting(false);
                })
                .catch((err) => {
                    console.log(err);
                    setSubmitError(err);
                    setIsSubmitting(false);
                });
            });
          });
    }

    const handleValueChanged = (s, o) => {
        localStorage.setItem(`${LOCALSTORAGEKEY}.${appId}`, JSON.stringify(s.valuesHash));
    }

    const handlePageJump = (page) => {
        setPageIndex(page);
    }

    const handlePageChange = (_, o) => {
        if (pageIndex !== o.newCurrentPage.visibleIndex) {
            setPageIndex(o.newCurrentPage.visibleIndex);
        }
    }
    
    const handleVisibleChanged = (_, o) => {
        if (!o.visible || o.isReadOnly) {
            o.question.value = null;
        }
    }

    const handleNextPage = () => target.current.survey.nextPage();
    const handlePrevPage = () => target.current.survey.prevPage();

    return (
        <div>
            { customColors.brandColor != undefined &&
                <TenantStyle brandColor={customColors.brandColor} darkBrand={customColors.darkBrand} textColor={customColors.textColor} />
            }
            { customCSS !== '' && 
                <style>{customCSS}</style>
            }
        <div className="mt-4 mb-2">
            { loading && 
                <div className="col-12 text-center v-100">
                    <p className="lead">Loading application...</p>
                    <p><i className="fa fa-spinner fa-3x fa-spin" aria-hidden="true"></i></p>
                </div>
            }
            { !loading && loadError !== '' &&
                <div>
                    <div className="page-wrap d-flex flex-row align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <span className="display-1 d-block mb-3">Oops!</span>
                                    <div className="mb-4 lead">There was an error loading the application: {loadError}</div>
                                    Please check the link you followed or request a new link.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            { isLoaded && !loading && loadError === '' &&
                <div className="p-2 bg-light">

                    <ContainerNavBar
                        logoUrl={logoUrl}
                        questionData={questionData}
                        handlePageJump={handlePageJump}
                        pageIndex={pageIndex}
                        displayNav={isLoaded && !submitted}/>

                    <Survey.Survey
                        ref={target}
                        json={questionData}
                        showCompletedPage={false}
                        showNavigationButtons ='none'
                        onValueChanged={handleValueChanged}
                        onVisibleChanged={handleVisibleChanged}
                        onCurrentPageChanged={handlePageChange}
                        currentPageNo={pageIndex} />

                    { isSubmitting &&
                        <div className="col-12 text-center v-100">
                            <p className="lead">Submitting application...</p>
                            <p><i className="fa fa-spinner fa-3x fa-spin" aria-hidden="true"></i></p>
                        </div>
                    }
                    { displayNav && !submitted &&
                        <ul className="nav nav-pills justify-content-end bg-light p-2" id='msq_Navigation'>
                            <li className='nav-item'>
                                <button className={`btn btn-outline-primary ${pageIndex === 0 ? "d-none" : ""}`}
                                    id='navPrevious'
                                    disabled={completeClicked}
                                    onClick={handlePrevPage}>Previous</button>
                            </li>
                            <li className='nav-item mx-2'>
                                <button className={`btn btn-outline-primary ${pageIndex === lastPageIndex ? "d-none" : ""}`}
                                    id='navNext'
                                    disabled={completeClicked}
                                    onClick={handleNextPage}>Next</button>
                            </li>
                            {pageIndex === lastPageIndex &&
                                <li className='nav-item mx-2'>
                                    <button className='btn btn-success'
                                        id='navComplete'
                                        disabled={completeClicked}
                                        onClick={handleCompleteClicked}>Complete</button>
                                </li>
                            }
                        </ul>
                    }

                    { submitError !== '' &&
                        <div>
                            <div className="page-wrap d-flex flex-row align-items-center">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <span className="display-1 d-block mb-3">Oops!</span>
                                            <div className="mb-4 lead">There was an error submitting your application: {submitError}</div>
                                            <button type="button"
                                                onClick={handleRecheck}
                                                className="btn btn-primary">Re-check my Application and Try Again</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    { submitted &&
                        <div className="mb-4">
                            <h4 className="text-center mt-3">{title} Received</h4>
                            <p className="m-3">We have received your application and will review it in consideration for
                                job opportunities that are suitable – current and future. If you have been selected as a short-listed candidate for a specific
                                position we will contact you for an interview. In many cases due to time constraints, only the short-listed candidates are
                                contacted but we do consider each application carefully.</p>

                        </div>
                    }

                    { pageIndex === lastPageIndex && !submitted &&
                        <p className="text-secondary text-center mt-4">
                            <small className="tiny">This site is protected by
                            reCAPTCHA and the Google
                            &nbsp;<a href="https://policies.google.com/privacy">Privacy Policy</a> and
                            &nbsp;<a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
                        </p>
                    }
                </div>
            }
        </div>
        <div className="mt-4 text-center">
            <small className="tiny">powered by <a href='https://mysimplehire.com?utm_source=powered-by&utm_medium=apply-web&utm_campaign=all-footer'><strong>mysimplehire</strong></a></small>
        </div>
    </div>
    )
}

export default QuestionnaireContainer;