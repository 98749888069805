import React from 'react';

const TenantStyle = ({brandColor, darkBrand, textColor}) => {

    let colorsCSS =  `.sv_container .sv_header{color:${textColor};background-color:${brandColor};}`
        + `.mshNavTab{color:${textColor} !important;background-color:${darkBrand} !important;`
        + 'border:none;margin: 0 0px 0 2px;'
        + 'padding-left: 8px !important;}'
        + `.active.mshNavTab {background-color:${brandColor} !important;}`
        + `.active.mshNavTab span {color:${textColor} !important;font-weight: bold;}`
        + `.sv_main input[type="button"],.sv_main button{color:${textColor};background-color:${brandColor};}`
        + `.sv_main input[type="button"]:hover,.sv_main button:hover {color:${brandColor};background-color:${textColor};}`
        + `.sv_main .sv_q_m_cell_selected{color:white;background-color:${brandColor};}`
        + `.sv_main .sv_select_wrapper:before{background-color:${brandColor};}`
        + `.sv_main .sv-boolean__switch{background-color:${brandColor};}`
        + `.sv_main .sv_q_imgsel.checked label > div{background-color:${brandColor};}`
        + `.sv_main .sv_q_rating_item.active .sv_q_rating_item_text{background-color:${brandColor};border-color:${brandColor};}`
        + `.sv_q_text_root:focus,.sv_q_dropdown_control:focus,select:focus,textarea:focus {border: 1px solid ${brandColor} !important;}`
        + `.sv_main .sv_progress_bar{background-color: ${darkBrand} !important;}`
        + `.sv_main .sv_body {border-top: 2px solid ${darkBrand} !important;}`;

    return (
        <style>{colorsCSS}</style>
    )
}

export default TenantStyle;